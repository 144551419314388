<template>
  <div v-if="!loading">
    <v-infinite-scroll
      :mode="mode"
      :side="side"
      :color="scssVariables.jColorPrimary"
      :load-more-text="$t('general_show_more')"
      class="overflow-y-hidden"
      :items="items"
      empty-text=""
      :margin="10"
      :height="items.length ? 'initial' : 0"
      @load="loadHandler"
    >
      <general-content-items-grid
        :items="items"
        :remote-storage-host="remoteStorageHost"
        :public-img-store-dir-name="publicImgStoreDirName"
        :column-count="columnCount"
        :column-gap="columnGap"
        :allowed-mode="allowedMode"
      />
      <template #load-more>
        <v-btn
          v-if="isLoadContent"
          class="j-btn text-none text-body-1 px-5 mt-8 d-block mx-auto"
          height="40"
          :color="scssVariables.jBtnRegular"
          variant="flat"
          size="large"
          @click="loadBtnHandler"
        >
          {{ $t('general_show_more') }}
        </v-btn>
      </template>
    </v-infinite-scroll>
    <general-text
      v-if="!items.length"
      :text-types="TEXT_TYPES.title"
      :text="noItemsText"
      class="d-flex justify-center mt-16"
    />
  </div>
  <div
    v-else
    class="text-center"
  >
    <v-progress-circular
      :size="50"
      :color="scssVariables.jColorPrimary"
      indeterminate
    />
  </div>
</template>
<script setup lang="ts">
import {
  TEXT_TYPES,
  GAMES_SLIDER_CONFIG,
} from '~/constants/general';
import type {
  Mode,
  Side,
  LoadParams,
} from '~/types/components/General/DynamicContentInfiniteScroll';
import type { AllowedMode } from '~/types/components/General/ContentGameCard';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
    required: false,
  },
  noItemsText: {
    type: String,
    default: '',
  },
  mode: {
    type: String as () => Mode,
    default: 'manual' as Mode,
  },
  side: {
    type: String as () => Side,
    default: 'end' as Side,
  },
  total: {
    type: Number,
    default: 0,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  remoteStorageHost: {
    type: String,
    default: '',
  },
  publicImgStoreDirName: {
    type: String,
    default: '',
  },
  loadContent: {
    type: Function,
    default: () => {},
  },
  allowedMode: {
    type: String as () => AllowedMode,
    default: undefined as AllowedMode,
  },
});

const {
  items,
  noItemsText,
  total,
  loading,
  remoteStorageHost,
  publicImgStoreDirName,
} = toRefs(props);
const isMobile = inject<Ref<boolean>>('isMobile');
const scssVariables = useScssVariables();
const emits = defineEmits(['update:mode']);
const isLoadContent = computed(() => items.value.length < total.value);
const columnCount = useColumnCount(GAMES_SLIDER_CONFIG, ['isXs', 'isMobile']);
const columnGap = computed(() =>
  isMobile?.value
    ? GAMES_SLIDER_CONFIG.mobile.gap
    : GAMES_SLIDER_CONFIG.desktop.gap,
);
const loadBtnHandler = async () => {
  await props.loadContent();
  emits('update:mode', 'intersect');
};
const loadHandler = async ({ done }: LoadParams) => {
  if (isLoadContent.value) {
    await props.loadContent();
    done('ok');
  } else {
    done('empty');
  }
};

// TODO: Avoid watchEffect
watchEffect(() => {
  if (isMobile?.value) {
    emits('update:mode', 'manual');
  }
});
</script>
