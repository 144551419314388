<template>
  <div class="j-game-filter">
    <v-toolbar
      :color="scssVariables.jBgBlock"
      class="pa-0 pr-6 pl-4 rounded-lg"
      height="56"
    >
      <v-slide-group
        v-model="tab"
        mandatory
      >
        <v-slide-group-item
          v-for="(currentIcon, index) in FILTERS"
          :key="index"
          :value="currentIcon.category"
          v-slot="{ toggle }"
        >
          <v-btn
            class="text-none pl-2 pr-2"
            :to="categoryLink(currentIcon.category)"
            @click.prevent="changeGameCategory(currentIcon.category), toggle()"
          >
            <div
              :class="[
                'd-flex',
                'align-center',
                'justify-center',
                {
                  'j-game-filter__btn-content j-game-filter__btn-content--underscore':
                    currentIcon.category === fetchGamesParamsData.gameCategory
                    && $route.path !== useLocalePrefixForHref('/'),
                },
              ]"
            >
              <v-img
                :src="images[currentIcon.icon]"
                :class="{
                  'j-game-filter__btn-icon j-game-filter__btn-icon--inactive':
                    currentIcon.category !== fetchGamesParamsData.gameCategory
                    || $route.path === useLocalePrefixForHref('/'),
                }"
                width="24px"
                :alt="`icon ${currentIcon.icon}`"
              />
              {{ $t(currentIcon.text) }}
            </div>
          </v-btn>
        </v-slide-group-item>
      </v-slide-group>
      <v-divider
        v-if="!isFocusedSearch"
        vertical
        class="ml-4"
      />
      <general-text-field
        v-model.trim="fetchGamesParamsData.optionalParams.search"
        :bg-color="scssVariables.jBgBlock"
        prepend-inner-icon="mdi-magnify"
        density="compact"
        :placeholder="$t('general_find_game')"
        :class="[
          'j-game-filter__search',
          'ma-0',
          'flex-shrink-0',
          'w-0',
          {
            'w-100': isFocusedSearch,
            'ml-4': !isFocusedSearch,
          },
        ]"
        @input="inputHandler"
        @update:focused="toggleSlideGroupWidth"
      />
    </v-toolbar>
    <div
      v-if="expand"
      class="d-flex justify-space-between align-center flex-column flex-lg-row"
    >
      <div class="d-flex mt-4 mt-lg-6 flex-column flex-lg-row w-100">
        <div class="j-game-filter__select mr-lg-4 mb-2 mb-lg-0">
          <general-select
            v-model="fetchGamesParamsData.gameProvider"
            :items="gameProvidersList"
            item-title="label"
            item-value="publishers[0]"
            class="w-100"
            @update:model-value="clickHandler(); changeMetaHandler()"
          >
            <template #selection="data">
              <span class="mr-1">{{ $t('general_provider') }}:</span>
              {{ data.item.title }}
            </template>
          </general-select>
        </div>
        <div class="j-game-filter__select mr-lg-4">
          <general-select
            v-model="fetchGamesParamsData.optionalParams.minBetDirection"
            :items="minBetDirectionsList"
            class="w-100"
            @update:model-value="clickHandler"
          >
            <template #selection="data">
              <span class="mr-1">{{ $t('general_by_bet') }}:</span>
              {{ data.item.title }}
            </template>
          </general-select>
        </div>
      </div>
      <div class="d-flex align-items-center justify-space-between justify-lg-end mt-6 w-100">
        <div class="d-flex flex-column flex-lg-row">
          <general-checkbox
            v-model="fetchGamesParamsData.optionalParams.gamesInTournamentOnly"
            :ripple="false"
            max-width="max-content"
            class="mr-4 mb-4 mb-lg-0"
            @update:model-value="clickHandler"
          >
            <general-text dictionary-key="general_tournament_games" />
          </general-checkbox>
          <general-checkbox
            v-model="fetchGamesParamsData.optionalParams.buyBonusFeature"
            :ripple="false"
            max-width="max-content"
            class="mr-4"
            @update:model-value="clickHandler"
          >
            <general-text dictionary-key="general_feature_buy" />
          </general-checkbox>
        </div>
        <div class="d-flex flex-column flex-lg-row mr-12 mr-lg-0">
          <general-checkbox
            v-model="fetchGamesParamsData.optionalParams.megawaysFeature"
            :ripple="false"
            max-width="max-content"
            class="mr-4 mb-4 mb-lg-0"
            @update:model-value="clickHandler"
          >
            <general-text dictionary-key="general_megaways" />
          </general-checkbox>
          <general-checkbox
            v-model="fetchGamesParamsData.optionalParams.gridSlotsFeature"
            :ripple="false"
            max-width="max-content"
            @update:model-value="clickHandler"
          >
            <general-text dictionary-key="general_grid_slots" />
          </general-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import {
  PLATFORM,
  FILTERS,
  GAMES_SLIDER_CONFIG,
  GAME_CATEGORIES,
  GAME_PROVIDERS,
  GAME_MIN_BET_DIRECTIONS,
} from '~/constants/general';
import { useGamesStore } from '~/stores/games';
import type { GameFilterParams } from '~/types/general/game';

const componentProps = defineProps({
  gameType: {
    type: String,
    default: '',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  expand: {
    type: Boolean,
    default: false,
  },
  changeMeta: {
    type: Boolean,
    default: false,
  },
});
const { $eventEmitter } = useNuxtApp();
const emit = defineEmits(['inputEvent', 'update:loading']);
const { t } = useI18n();
const {
  gameType,
  expand,
  changeMeta,
} = toRefs(componentProps);
const tab = ref('ALL');
const fetchGamesParamsData = ref({
  gameCategory: GAME_CATEGORIES.all,
  gameProvider: GAME_PROVIDERS.all,
  offset: 0,
  limit: 0,
  platform: '',
  optionalParams: {
    search: '',
    buyBonusFeature: false,
    gamesInTournamentOnly: false,
    gridSlotsFeature: false,
    label: '',
    megawaysFeature: false,
    minBetDirection: '',
  },
});
const gameProvidersList = computed(() => {
  return [
    {
      label: t('general_all'),
      publishers: [GAME_PROVIDERS.all],
      urlMapping: GAME_PROVIDERS.all.toLowerCase(),
    },
    ...getGameProviders.value,
  ];
});
const minBetDirectionsList = ref([
  {
    title: t('general_all'),
    value: GAME_MIN_BET_DIRECTIONS.all,
  },
  {
    title: `Max - min ${t('general_bet').toLowerCase()}`,
    value: GAME_MIN_BET_DIRECTIONS.asc,
  },
  {
    title: `Min - max ${t('general_bet').toLowerCase()}`,
    value: GAME_MIN_BET_DIRECTIONS.desc,
  },
]);
const isMobile = inject<Ref<boolean>>('isMobile');
const isXs = inject<Ref<boolean>>('isXs');
const { isMobileOrTablet } = useDevice();
const currentPlatform = computed(() =>
  isMobileOrTablet
    ? PLATFORM.mobile
    : PLATFORM.desktop,
);
const limit = computed(() =>
  isXs?.value
    ? GAMES_SLIDER_CONFIG.xs.allGamesChunkSize
    : isMobile?.value
      ? GAMES_SLIDER_CONFIG.mobile.allGamesChunkSize
      : GAMES_SLIDER_CONFIG.desktop.allGamesChunkSize,
);
const categoryLink = (iconCategory: string) => {
  const pathPrefix = route.path.includes('new-games')
    ? 'new-games'
    : 'games';
  const gameProvider = fetchGamesParamsData.value.gameProvider.replaceAll('_', '-').toLowerCase();
  const category = iconCategory.replaceAll('_', '-').toLowerCase();

  return useLocalePrefixForHref(`/${pathPrefix}/${gameProvider}/${category}`);
};
const gamesStore = useGamesStore();
const {
  fetchGamesByType,
  fetchGamesByTypeForSSR,
} = gamesStore;
const { getGameProviders } = storeToRefs(gamesStore);
const debounceGameFiltering = useDebounce(async () => {
  changeParamsHandler();
  await loadGames();
  emit('update:loading', false);
}, 500);

const loadGames = async (appendFetchedGames = false) => {
  if (!appendFetchedGames) {
    fetchGamesParamsData.value.offset = 0;
  }
  const gamesParams = {
    ...fetchGamesParamsData.value.optionalParams,
    search: encodeURIComponent(fetchGamesParamsData.value.optionalParams.search),
    gameCategory: fetchGamesParamsData.value.gameCategory,
    gameProvider: fetchGamesParamsData.value.gameProvider,
    offset: fetchGamesParamsData.value.offset,
    limit: limit.value,
    platform:
      currentPlatform.value === PLATFORM.xs
        ? PLATFORM.mobile
        : currentPlatform.value,
  };

  Object.keys(gamesParams).forEach((key) => {
    const value = gamesParams[key as keyof GameFilterParams] as
      | string
      | boolean
      | null
      | undefined;

    if ([false, '', null, undefined].includes(value)) {
      delete gamesParams[key as keyof GameFilterParams];
    }
  });

  await fetchGamesByType(gamesParams, gameType.value, appendFetchedGames);
  fetchGamesParamsData.value.offset = appendFetchedGames
    ? fetchGamesParamsData.value.offset + limit.value
    : limit.value;
};
const loadGamesSSR = async () => {
  const gamesParams = {
    ...fetchGamesParamsData.value.optionalParams,
    search: encodeURIComponent(fetchGamesParamsData.value.optionalParams.search),
    gameCategory: fetchGamesParamsData.value.gameCategory,
    gameProvider: fetchGamesParamsData.value.gameProvider,
    offset: fetchGamesParamsData.value.offset,
    limit: limit.value,
    platform:
      currentPlatform.value === PLATFORM.xs
        ? PLATFORM.mobile
        : currentPlatform.value,
  };

  Object.keys(gamesParams).forEach((key) => {
    const value = gamesParams[key as keyof GameFilterParams] as
      | string
      | boolean
      | null
      | undefined;

    if ([false, '', null, undefined].includes(value)) {
      delete gamesParams[key as keyof GameFilterParams];
    }
  });

  await fetchGamesByTypeForSSR(gamesParams, gameType.value);
};
const inputHandler = async (event: { target: { value: string } }) => {
  const truncatedValue =
    event.target.value.length > 50
      ? event.target.value.slice(0, 50)
      : event.target.value;

  fetchGamesParamsData.value.optionalParams.search = truncatedValue;

  emit('inputEvent', Boolean(truncatedValue));
  fetchGamesParamsData.value.offset = 0;
  emit('update:loading', true);
  await debounceGameFiltering();
};
const toggleSlideGroupWidth = (event: boolean) => {
  if (isMobile?.value) {
    isFocusedSearch.value = event;
  }
};
const isFocusedSearch = ref(false);
const clickHandler = async () => {
  changeParamsHandler();
  await loadGames();
};
const changeMetaHandler = () => {
  if (changeMeta.value) {
    usePageMeta(
      t(
        `games_provider_${(
          fetchGamesParamsData.value.gameProvider.toLowerCase() as string
        )}.page_title`,
      ),
      t(
        `games_provider_${(
          fetchGamesParamsData.value.gameProvider.toLowerCase() as string
        )}.page_description`,
      ),
    );
  }
};
const camelToHyphenCase = (str: string) => {
  if (str === str.toUpperCase()) {
    return str.toLowerCase();
  } else {
    return str.replace(/[A-Z]/g, (match) => '-' + match.toLowerCase());
  }
};
const hyphenToCamelCase = (str: string) => {
  if (!str.includes('-')) {
    return str.toUpperCase();
  } else {
    return str.replace(/-[a-z]/g, (match) => match[1].toUpperCase());
  }
};
const changeParamsHandler = () => {
  let params = '';

  Object.entries(fetchGamesParamsData.value.optionalParams).forEach(
    ([key, value]) => {
      if (![false, '', null, undefined].includes(value)) {
        const paramKey = key === 'search'
          ? key
          : camelToHyphenCase(key);
        const paramValue = typeof value !== 'boolean'
          ? `=${paramKey === 'search'
            ? value
            : camelToHyphenCase(value)}`
          : '';

        params += `/${paramKey}${paramValue}`;
      }
    },
  );

  const currentProvider = gameProvidersList.value.find(
    (provider) =>
      provider.publishers[0] === fetchGamesParamsData.value.gameProvider,
  );
  const url = router.resolve({
    params: {
      gameProvider: currentProvider?.urlMapping,
      gameCategory: fetchGamesParamsData.value.gameCategory.replaceAll('_', '-').toLowerCase(),
      params: params.substring(1),
    },
  }).path;

  window.history.pushState({}, '', decodeURIComponent(url));
  $eventEmitter.emit(
    'get-game-category',
    fetchGamesParamsData.value.gameCategory,
  );
};
const scssVariables = useScssVariables();
const images = useAssetsImages();
const route = useRoute();
const router = useRouter();
const changeGameCategory = async (category: string) => {
  if (expand.value) {
    fetchGamesParamsData.value.gameCategory = category;
    tab.value = category;
    changeParamsHandler();
    await loadGames();
  } else {
    const gamesRoute = useLocalePrefixForHref(`/games/${GAME_PROVIDERS.all}/${category}`.toLowerCase());

    router.push({ path: gamesRoute });
  }
};
const setParamsToFetchGamesParams = () => {
  let filterParams: { [key: string]: string | boolean } = {};

  if (route.params.params.length) {
    (route.params.params as string[]).forEach((param) => {
      const [key, value] = param.includes('=')
        ? param.split('=')
        : [param, 'true'];
      const formattedKey = key === 'search'
        ? key
        : hyphenToCamelCase(key);
      const formattedValue = key === 'search' || value === 'true'
        ? value
        : hyphenToCamelCase(value);

      filterParams[formattedKey] = formattedValue;
    });
  }

  const setParam = (param: string, defaultValue: string = '') =>
    filterParams[param]
      ? String(filterParams[param])
      : defaultValue;

  const currentProvider = gameProvidersList.value.find(
    (provider) => provider.urlMapping === route.params.gameProvider,
  );

  fetchGamesParamsData.value.gameProvider = setParam(
    'gameProvider',
    currentProvider?.publishers[0],
  );
  fetchGamesParamsData.value.gameCategory = setParam(
    'gameCategory',
    String(route.params.gameCategory).replaceAll('-', '_').toUpperCase(),
  );
  fetchGamesParamsData.value.optionalParams.search = setParam('search');
  fetchGamesParamsData.value.optionalParams.buyBonusFeature =
    filterParams.buyBonusFeature === 'true';
  fetchGamesParamsData.value.optionalParams.gamesInTournamentOnly =
    filterParams.gamesInTournamentOnly === 'true';
  fetchGamesParamsData.value.optionalParams.gridSlotsFeature =
    filterParams.gridSlotsFeature === 'true';
  fetchGamesParamsData.value.optionalParams.label = setParam('label');
  fetchGamesParamsData.value.optionalParams.megawaysFeature =
    filterParams.megawaysFeature === 'true';
  fetchGamesParamsData.value.optionalParams.minBetDirection =
    setParam('minBetDirection');
};

onMounted(() => {
  nextTick(async () => {
    $eventEmitter.on('change-game-category', (category: string) =>
      changeGameCategory(category),
    );
    $eventEmitter.emit(
      'get-game-category',
      fetchGamesParamsData.value.gameCategory,
    );
  });
});
onUnmounted(() => {
  $eventEmitter.off('change-game-category');
  $eventEmitter.emit('get-game-category', GAME_CATEGORIES.all);
});
defineExpose({ loadGames });
/**
 * Fetches games data when component is server-side rendered.
 */
 await useAsyncData(
  'fetch-games-data',
  async () => {
    if (expand.value) {
      setParamsToFetchGamesParams();
      tab.value = fetchGamesParamsData.value.gameCategory;

      await loadGamesSSR();
    }
  },
  { server: true },
);
// TODO: Logic Extremely need refactoring
watch([isMobile, isXs], () => loadGames());
</script>

<style scoped lang="scss">
.j-game-filter__btn-content {
  &.j-game-filter__btn-content--underscore {
    border-bottom: 2px solid $j-color-primary;
  }
}
.j-game-filter__btn-icon {
  &.j-game-filter__btn-icon--inactive {
    filter: grayscale(100%);
    transition: filter 0.3s, transform 0.3s, opacity 0.3s;
    opacity: 0.3;
  }
}
.j-game-filter__select {
  :deep(.v-select__selection span) {
    color: $j-text-secondary;
  }
  @media (min-width: 1280px) {
    min-width: 220px;
  }
}
.j-game-filter__search {
  min-width: 40px;
  transition: width 0.5s;
}
</style>
